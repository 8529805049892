import { format, parse } from "date-fns";
import { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Id } from "../models/Id";
import { useGetDeliveryOrganizationsQuery } from "../gateways/cms/deliveryGroupApi";
import { useGetBillingDatasQuery } from "../gateways/cms/billingRuleApi";
import { BillingData, Period } from "../gateways/cms/billingRule";
import {
    Article,
    ArticleForm as ArticleFormData,
    ArticleStatus,
    ArticleSchemaItem,
    DeliveryOrganizationForm,
    OrganizationBillingRuleForm,
    //ArticleBillingInfo,
    ArticleBillingInfoForm,
} from "../gateways/cms/article";
import { DeliveryOrganizationsFormGroup } from "./DeliveryOrganizationsFormGroup";
//import { OrganizationBillingRulesInput } from "./OrganizationBillingRulesInput";
import { OrganizationBillingDatasInput } from "./OrganizationBillingDatasInput";
import { SelectArticleType } from "./SelectArticleType";
import { SelectDate } from "./SelectDate";
import { SelectDay } from "./SelectDay";
import { confirmWithStyle } from "../components/MyConfirm";
import { ArticleSchemaForm } from "./ArticleSchemaForm";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { getSchemaDefinition } from "../gateways/cms/schemaApi";
import { useGetMyUserQuery } from "../gateways/cms/userApi";
import { useSelector } from "react-redux";
import { RootState } from "../store";

import { styled } from "@mui/system";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import ButtonMUI from "@mui/material/Button";
import VarConst from "../styles/variables.js";
import Color from "../styles/colors.js";
import iconRadioOff from "../assets/icons/icon_radiobutton_off.svg";
import iconRadioOn from "../assets/icons/icon_radiobutton_on.svg";
import iconCheckboxOff from "../assets/icons/icon_checkbox_off.svg";
import iconCheckboxOn from "../assets/icons/icon_checkbox_on.svg";
import iconCalendar from "../assets/icons/icon_calendar.svg";
import iconSelectDown from "../assets/icons/icon_arrow_down.svg";

// [コンテンツ編集] メイン

const schema = Yup.object({
    articleSchema: Yup.array(
        Yup.object({
            id: Yup.number().required(),
            content: Yup.lazy((content) =>
                Yup.object({
                    type: Yup.string().required(),
                    value:
                        content.type === "file" || content.type === "minimum-schema" || content.type === "signage"
                            ? Yup.object({
                                data: Yup.string(),
                                mimetype: Yup.string(),
                                filename: Yup.string(),
                            }).required()
                            : Yup.array(Yup.string()).required(),
                })
            ),
        })
    ),
});
const resolver = yupResolver(schema);

export interface Props {
    article?: Article;
    deleteButton?: JSX.Element;
    organizationId: Id;
    checkSaveAs: boolean;
    onSubmit?: (articleForm: ArticleFormData) => void;
    articleApprove?: boolean;
    isEdit: boolean;
}

enum SaveAction {
    Publish,
    PublishAndNotify,
    Request,
    Draft,
    Cancel,
}

// style
const SectionTitle = styled("h2")`
    margin: 0 0 24px;
    padding: 10px 10px 10px 40px;
    height: 40px;
    font-weight: normal;
    font-size: 20px;
    background-color: #f4f4f4;
    line-height: 1;
    border-bottom: 2px solid #000;

    & + div {
        margin: 24px 24px 48px;

        input,
        select {
            height: 32px;
            border: ${VarConst.border1};
        }
        label {
            display: flex;
            margin-bottom: 8px;

            & ~ input[type="text"],
            & + select {
                width: 100%;
            }
            & input[type="date"] {
                width: 12em;
            }
            & input[type="time"] {
                width: 6em;
            }
        }
    }
`;
const Button = styled(ButtonMUI)`
    height: 32px;
    width: 8.5em;
    border-radius: 2px;
`;
const ButtonWhite = styled(Button)`
    border: ${VarConst.border2};
    background-color: #fff;
`;
const ButtonSetBilling = styled(ButtonWhite)`
    width: 14em;
    margin-bottom: 32px;
`;
const LabelRadio = styled("label")`
    display: flex;
    align-items: center;
    position: relative;

    input[type="radio"] {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        width: 24px;
        height: 24px;
        margin: 0;
        opacity: 0;
        cursor: pointer;
        transform: translate( -50%, -50%);
        
        & + span{
            display: flex;
            align-items: center;
            margin-left: 0 !important;

            &::before {
                content: "";
                display: inline-block;
                width: 24px;
                height: 24px;
                margin-right: 0.5em;
                pointer-events: none;
                background: transparent url(${iconRadioOff}) scroll no-repeat center center;
            }
        }
        &:checked + span::before {
            background-image: url(${iconRadioOn});
        }

        & ~ b {
            margin-left: 0.65em;
            font-weight: normal;
        }
    }

`;
const InputBasic = styled(Grid)`
    width: 800px;
    padding-bottom: 24px;
    
    select,
    textarea,
    input[type="date"],
    input[type="time"],
    input[type="text"]{
        padding: 4px 8px;
    }
`;
const InputSchedule = styled(Grid)`
    width: 1300px;

    label {
        & ~ input:not([type="radio"]) {
            width: auto;
        }
        & > div {
            align-items: center;
        }
    }
`;
const InputWeekly = styled(Stack)`
    margin-bottom: 16px;

    label {
        &:nth-of-type(1) {
            color: #f00;
        }
        &:nth-of-type(7) {
            color: #00f;
        }

        display: flex;
        align-items: center;

        input[type="checkbox"] {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;
            width: 24px;
            height: 24px;
            margin: 0;
            opacity: 0;
            cursor: pointer;
            transform: translate( -50%, -50%);

            & + span{
                display: flex;
                align-items: center;

                &::before {
                    content: "";
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    margin-right: 0.5em;
                    pointer-events: none;
                    background: transparent url(${iconCheckboxOff}) scroll no-repeat center center;
                }
            }
            &:checked + span::before {
                background-image: url(${iconCheckboxOn});
            }
        }
    }
`;
const InputCalender = styled("section")`
    .selectCalender {
        display: flex;
        flex-wrap: wrap;
        width: calc((2.5em * 7) + 2px);
        margin-bottom: 16px;
        border: 1px solid #ccc;
        border-width: 2px 0 0 2px;

        label {
            width: 2.5em;
            height: 2.5em;
            margin: 0;
            position: relative;

            input {
                position: absolute;
                z-index: 0;
                opacity: 0;

                &:checked + b {
                    background-color: #3a3e5d;
                    color: #fff;
                }
            }
            b {
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #ccc;
                border-width: 0 2px 2px 0;
                width: 2.5em;
                height: 2.5em;
                font-weight: normal;
            }
        }

        & + div {
            width: calc((2.5em * 7) + 2px);
            justify-content: space-around;
        }
    }
`;
const InputDate = styled("input")`
    padding: 4px;
    background: transparent url(${iconCalendar}) right center no-repeat scroll;

    &::-webkit-calendar-picker-indicator {
        cursor: pointer;
        background-image: none;
    }
`;
const InputTime = styled("input")`
    padding: 4px;
    background: transparent url(${iconSelectDown}) right center no-repeat scroll;

    &::-webkit-calendar-picker-indicator {
        cursor: pointer;
        background-image: none;
    }
`;
/*
const SelectWithIcon = styled("select")`
    appearance: none;
    padding-right: 1.8em;
    border-radius: 0;
    background: transparent url(${iconSelectDown}) right center no-repeat scroll;
`;
*/
const BarFooter = styled("footer")`
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: end;
    padding: 16px 24px;
    background-color: ${Color.bgHead};
    margin-bottom: -64px;

    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;

    & > div {
        align-items: end;

        div {
            display: flex;
            flex-wrap: wrap;
            width: 30em;

            label {
                line-height: 32px;
                margin-right: 1em;
                margin-bottom: 8px;
            }
            input[type="text"] {
                width: 100%;
                height: 32px;
            }
        }
    }
`;

export function ArticleForm({ article, deleteButton, organizationId, checkSaveAs, onSubmit = () => { }, articleApprove, isEdit }: Props) {
    //console.log(article);
    const {
        control,
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: article,
        resolver,
    });
    const token = useSelector((state: RootState) => state.auth.token);
    const navigate = useNavigate();
    const user = useGetMyUserQuery();

    const [articleId] = useState(String(article?.id || ""));
    const [title, setTitle] = useState(article?.title || "");
    const [draft, setDraft] = useState(article?.draft || false);
    const [articleType, setArticleType] = useState(String(article?.schemaGroupId || ""));
    const [periodDayStartDate, setPeriodDayStartDate] = useState(date(article?.periodDayStart));
    const [periodDayStartTime, setPeriodDayStartTime] = useState(time(article?.periodDayStart) || "00:00");
    const [periodDayEndDate, setPeriodDayEndDate] = useState(date(article?.periodDayEnd));
    const [periodDayEndTime, setPeriodDayEndTime] = useState(time(article?.periodDayEnd) || "23:59");
    const [periodDayNoLimit, setPeriodDayNoLimit] = useState(!(article?.periodDayStart && article?.periodDayEnd));
    const [periodWeek, setPeriodWeek] = useState(article?.periodWeek || [1, 1, 1, 1, 1, 1, 1]);
    const [periodDay, setPeriodDay] = useState(article?.periodDay || [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]);
    const [periodTimeStart, setPeriodTimeStart] = useState(article?.periodTimeStart || "00:00");
    const [periodTimeEnd, setPeriodTimeEnd] = useState(article?.periodTimeEnd || "23:59");
    //const [displayCountPerHour, setDisplayCountPerHour] = useState(article?.displayCountPerHour);

    const checkArticle = Number(articleId || 0);
    const schemaGroupId = Number(articleType || 0);
    const [articleDelivery, setArticleDelivery] =  useState(checkArticle);
    const deliveryOrganizations = useGetDeliveryOrganizationsQuery({ organizationId, schemaGroupId, articleDelivery}).currentData;
    const billingDatas = useGetBillingDatasQuery(organizationId).data;
    const [checkMinimumSchema, setMinimumSchema] = useState(false);

    const [deliveries, setDeliveries] = useState<DeliveryOrganizationForm[]>(deliveryOrganizations || []);
    useLayoutEffect(() => {
        setDeliveries(deliveryOrganizations || []);
    }, [deliveryOrganizations]);
    const didMountRef = useRef(false);
    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        }else{
            setArticleDelivery(0);
        }
    }, [organizationId]);

    const getBillingInfos = (article:Article) => article?.billingInfo || [];
    const articleBillingInfos: ArticleBillingInfoForm[] = article  !== undefined ? [...getBillingInfos(article)] : [] ;
    
    const convBillingInfo = (articleBillingInfo:ArticleBillingInfoForm, index:number): OrganizationBillingRuleForm => {
        const period: Period = {
            monthFlg: 0,
            day: 0,
        }
        const billingdatas: BillingData = {
            ...articleBillingInfo,
            id: articleBillingInfo.id,
            name: articleBillingInfo.name,
            remarks: "",
            billingMonth: articleBillingInfo.billingMonth,
            billingName: articleBillingInfo.billingName,
            periodFrom: period,
            periodTo: period,
            packPrice: 0,
            contentsMax: 0,
            overageCharges: 0,
            index: index,
        }
        const billing: OrganizationBillingRuleForm = {
            ...articleBillingInfo,
            organizationId: articleBillingInfo.organizationId,
            name: articleBillingInfo.name,
            billingName: articleBillingInfo.billingName,
            billingRuleId: articleBillingInfo.billingRuleId,
            index: index,
            billingDatas: [billingdatas],
        };
        return billing;
    };
    const convBillingInfos = (articleBillingInfos:ArticleBillingInfoForm[]): OrganizationBillingRuleForm[] => {
        const billings: OrganizationBillingRuleForm[] = articleBillingInfos.map((articleBillingInfo, index) => {
            return convBillingInfo(articleBillingInfo, index);
        });
            
        return billings;
    };

    const billingInfos = convBillingInfos(articleBillingInfos);

    const [organizationBillingRules, setOrganizationBillingRules] = useState<OrganizationBillingRuleForm[]>(
        billingInfos
    );

    const checkApprove = (article: Article | undefined):number => {
        if(!article){
            return SaveAction.Publish;
        } 
        if(article.status === "確認中"){
            return SaveAction.Request;
        }
        else if(article.approvedBy){
            return SaveAction.Request;
        }
        else {
            return SaveAction.Publish;
        }
    };
    const [saveAction, setSaveAction] = useState(checkApprove(article));
    //const [saveComment, setSaveComment] = useState("");

    const checkCreatedBy = (article: Article):boolean => {
        if(user.data?.checkAdmin) return true;
        if(article.createdBy.id === user.data?.id) return true; 
        return false;
    };

    const saveAsDraft = (status: ArticleStatus, data: any) => {
        const articleForm: ArticleFormData = {
            ...data,
            id: article?.id,
            organizationId,
            status: ArticleStatus.DRAFT,
            title,
            articleType,
            periodDayStart: periodDayString(periodDayStartDate, periodDayStartTime, periodDayNoLimit),
            periodDayEnd: periodDayString(periodDayEndDate, periodDayEndTime, periodDayNoLimit),
            periodWeek,
            periodDay,
            periodTimeStart,
            periodTimeEnd,
            // displayCountPerHour,
            draft,
            delivery: deliveries,
            billingInfo: organizationBillingRules,
        };

        onSubmit(articleForm);
    };

    const saveAs = (status: ArticleStatus, data: any) => {
        // TODO: ちゃんとしたバリデーション

        const articleForm: ArticleFormData = {
            ...data,
            id: article?.id,
            organizationId,
            status,
            title,
            articleType,
            periodDayStart: periodDayString(periodDayStartDate, periodDayStartTime, periodDayNoLimit),
            periodDayEnd: periodDayString(periodDayEndDate, periodDayEndTime, periodDayNoLimit),
            periodWeek,
            periodDay,
            periodTimeStart,
            periodTimeEnd,
            // displayCountPerHour,
            draft,
            delivery: deliveries,
            billingInfo: organizationBillingRules,
        };

        onSubmit(articleForm);
    };
    /*** インテラブル判定の必要がなさそうなのでコメントアウト
    const isIterable = (obj:any) => 
        typeof obj !== "object" || obj === null 
        ? false 
        : typeof obj[Symbol.iterator] === "function";
    コメントアウトここまで ***/

    const save = (data: any) => {
        if(saveAction !== SaveAction.Draft){
            // コンテンツタイプの入力チェック
            if(!articleType){
                alert("コンテンツタイプを選択してください");
                return;
            }
            // コンテンツアイテムの入力チェック
            let typeName = "コンテンツ";
            if(!data?.articleSchema){
                alert(typeName + "を1つ以上作成してください");
                return;
            }
            else{
                let schemas:ArticleSchemaItem[] = data.articleSchema;
                let contentCount = 0;
                for(const schema of schemas){
                    if(schema.content){
                        /*** 設定必須コンテンツアイテムの入力チェック ***/
                        if(schema.content.required){
                            // 回数保証コンテンツorサイネージ用コンテンツの場合
                            if(schema.content.type === "minimum-schema" || schema.content.type === "signage"){
                                if(!schema.content.value?.data){
                                    alert("コンテンツタイプ「" + schema.title + "」のコンテンツを入力してください");
                                    return;
                                }
                                // サイネージURLの場合
                                if(schema.content.value?.type === "signage-url" && !schema.content.value?.url){
                                    alert("コンテンツタイプ「" + schema.title + "」のURLを入力してください");
                                    return;
                                }
                                // サイネージ短文の場合
                                if(schema.content.value?.type === "signage-text" && !schema.content.value?.text){
                                    alert("コンテンツタイプ「" + schema.title + "」の文字列を入力してください");
                                    return;
                                }
                            }
                            
                            // それ以外のコンテンツの場合
                            else{
                                let values:any[] = [];
                                /*** インテラブル判定の必要がなさそうなのでコメントアウト
                                if(isIterable(schema.content.value)){
                                    values = schema.content.value;
                                }else{
                                    values.push(schema.content.value);
                                }
                                コメントアウトここまで ***/
                                values.push(schema.content.value);

                                for(const value of values){
                                    if(!value || (Array.isArray(value) && !value[0])){
                                        alert("コンテンツタイプ「" + schema.title + "」のコンテンツを入力してください");
                                        return;
                                    }
                                }
                            }
                        }

                        /*** 添付ファイルのmimeTypeチェック ***/
                        if(schema.content.value?.data){
                            // dataのヘッダー取得
                            const dataHeader = schema.content.value.data.split(";", 1);

                            /* サイネージ用添付ファイルのmimeTypeチェック */
                            if(schema.content.type === "signage"){
                                // mimeTypeが設定されてない
                                if(!schema.content.value?.mimetype){
                                    alert("コンテンツタイプ「" + schema.title + "」を設定してください");
                                    return;
                                }
                                // サイネージURL or 短文の場合
                                if(schema.content.value?.type === "signage-url" || schema.content.value?.type === "signage-text"){
                                    if(schema.content.value?.mimetype !== "text/plain"){
                                        alert("コンテンツタイプ「" + schema.title + "」の代替には画像ファイルを設定してください");
                                        return;
                                    }
                                }
                                // サイネージ画像の場合
                                else if(schema.content.value?.type === "signage-image"){
                                    if(!schema.content.value?.mimetype.includes("image")){
                                        alert("コンテンツタイプ「" + schema.title + "」には画像ファイルを設定してください");
                                        return;
                                    }
                                }
                                // サイネージ動画の場合
                                else if(schema.content.value?.type === "signage-video"){
                                    if(!schema.content.value?.mimetype.includes("video")){
                                        alert("コンテンツタイプ「" + schema.title + "」には動画ファイルを設定してください");
                                        return;
                                    }
                                }
                            }

                            /* 回数保証用添付ファイルのmimeTypeチェック */
                            if (schema.content.type === "minimum-schema") {
                                // mimeTypeが設定されてない
                                if(!schema.content.value?.mimetype){
                                    alert("コンテンツタイプ「" + schema.title + "」を設定してください1");
                                    return;
                                }
                                // 画像の場合
                                if(dataHeader[0].includes("image") && !schema.content.value?.mimetype.includes("image")){
                                    alert("コンテンツタイプ「" + schema.title + "」には画像ファイルを設定してください");
                                    return;
                                }
                                // 動画の場合
                                if(dataHeader[0].includes("video") && !schema.content.value?.mimetype.includes("video")){
                                    alert("コンテンツタイプ「" + schema.title + "」には動画ファイルを設定してください");
                                    return;
                                }
                            }
                        }
                        // dataが含まれない場合
                        else{
                            if(schema.content.type === "signage" || schema.content.type === "minimum-schema"){
                                if(schema.content.value?.index !== undefined){
                                    if(schema.content.value?.mimetype.includes("image")){
                                        alert("画像が選択されていますが画像ファイルが添付されていません");
                                        return;
                                    }
                                    else if(schema.content.value?.mimetype.includes("video")){
                                        alert("動画が選択されていますが動画ファイルが添付されていません");
                                        return;
                                    }
                                    else if(schema.content.value?.mimetype.includes("url")){
                                        alert("URLが選択されていますが代替画像が添付されていません");
                                        return;
                                    }
                                    else if(schema.content.value?.mimetype.includes("text")){
                                        alert("短文が選択されていますが代替画像が添付されていません");
                                        return;
                                    }
                                    else{
                                    //    alert("未設定のコンテンツ項目があります");
                                    //    return;
                                    }
                                }
                            }
                        }

                        /*** 入力したコンテンツアイテム数チェック ***/
                        // 回数保証コンテンツの場合
                        if(schema.content.type === "minimum-schema"){
                            typeName = "回数保証コンテンツ";
                            if(schema.content.value?.data){
                                contentCount++;
                            }
                        }
                        // サイネージ用コンテンツの場合
                        else if(schema.content.type === "signage"){
                            typeName = "サイネージ用コンテンツ";
                            if(schema.content.value?.data || schema.content.value?.url){
                                contentCount++;
                            }
                        }
                        // ファイルの場合
                        else if(schema.content.type === "file"){
                            if(schema.content.value?.data){
                                contentCount++;
                            }
                        }
                        // それ以外のコンテンツの場合
                        else{
                            if(schema.content.value[0]){
                                contentCount++;
                            }
                        }
                    }
                }
                if(contentCount === 0){
                    alert(typeName + "を1つ以上作成してください");
                    return;
                }
            }
            // スケジュールの入力チェック
            if(!periodDayNoLimit){
                if(!periodDayStartDate || !periodDayStartTime || !periodDayEndDate || !periodDayEndTime){
                    alert("掲載期間を設定してください");
                    return;
                }
                else if(periodDayStartDate > periodDayEndDate){
                    alert("掲載開始日以降に掲載終了日を設定してください");
                    return;
                }
                if(!periodTimeStart || !periodTimeEnd){
                    alert("掲載時間を設定してください");
                    return;
                }
                else if(periodTimeStart >= periodTimeEnd){
                    alert("掲載開始時間より後に掲載終了時間を設定してください");
                    return;
                }
            }
            // 配信先の入力チェック
            let deliveryCount = 0;
            if(deliveries){
                deliveries.forEach((delivery) => {
                    if(delivery.articleType){
                        deliveryCount += delivery.deliveryGroup.length;
                    }
                });
            }
            if(!deliveries || deliveryCount === 0){
                alert("配信先を1つ以上設定してください");
                return;
            }
        }

        switch (saveAction) {
            case SaveAction.Publish:
                // // eslint-disable-next-line no-restricted-globals
                // if (!confirm("コンテンツをそのまま掲載します。よろしいですか？")) return;
                // saveAs(ArticleStatus.PUBLISHED);
                // break;
                (async () => {
                    const isConfirmed = await confirmWithStyle({
                        title: "掲載",
                        body: "コンテンツを投稿します。投稿完了後は自動的にコンテンツ管理画面に戻ります。よろしいですか？",
                        okButtonText: "掲載",
                    });
                    if (isConfirmed) {
                        saveAs(ArticleStatus.PUBLISHED, data);
                    }
                })();
                break;
            case SaveAction.PublishAndNotify:
                // // eslint-disable-next-line no-restricted-globals
                // if (!confirm("コンテンツをテナント管理者と一般ユーザー全員に通知して掲載します。よろしいですか？")) return;
                // saveAs(ArticleStatus.PUBLISHED);
                (async () => {
                    const isConfirmed = await confirmWithStyle({
                        title: "通知して掲載",
                        body: "コンテンツをテナント管理者と一般ユーザー全員に通知して掲載します。よろしいですか？",
                        okButtonText: "掲載",
                    });
                    if (isConfirmed) {
                        saveAs(ArticleStatus.PUBLISHED, data);
                        // TODO: 通知
                    }
                })();
                break;
            case SaveAction.Request:
                // // eslint-disable-next-line no-restricted-globals
                // if (!confirm("一般ユーザーにコンテンツ掲載の承認依頼を送信します。よろしいですか？")) return;
                // saveAs(ArticleStatus.PENDING);
                (async () => {
                    const isConfirmed = await confirmWithStyle({
                        title: "承認依頼",
                        body: "一般ユーザーにコンテンツ掲載の承認依頼を送信します。よろしいですか？",
                        okButtonText: "承認依頼",
                    });
                    if (isConfirmed) {
                        saveAs(ArticleStatus.PENDING, data);
                        // TODO: 承認依頼
                    }
                })();
                break;
            case SaveAction.Draft:
                (async () => {
                    /** 確認ポップアップなしのためコメントアウト ***
                    const isConfirmed = await confirmWithStyle({
                        title: "下書き",
                        body: "コンテンツを下書き保存します。よろしいですか？",
                        okButtonText: "下書き保存",
                    });
                    if (isConfirmed) {
                        saveAsDraft(ArticleStatus.DRAFT, data);
                    }
                    *** コメントアウトここまで **/
                    // 確認ポップアップ復活の場合はこちらを削除
                    saveAsDraft(ArticleStatus.DRAFT, data);
                })();
                break;
        }
    };

    const handleChangeArticleType = async (type: string) => {
        if(articleType){
            (async () => {
                const isConfirmed = await confirmWithStyle({
                    title: "コンテンツタイプ変更",
                    body: "コンテンツタイプを変更すると設定中のコンテンツが消えます。よろしいですか？",
                    okButtonText: "変更",
                });
                if (isConfirmed) {
                    changeArticleType(type);
                }
            })();
        }
        else{
            changeArticleType(type);
        }
    };
    // コンテンツタイプを変更する
    const changeArticleType = async (type: string) => {
        setArticleType(type);
        setArticleDelivery(0);
        setDeliveries(deliveryOrganizations || []);
        if (type) {
            const fileSchema = ["file", "minimum-schema", "signage"];
            const schema = await getSchemaDefinition(token, Number(type));
            setValue(
                "articleSchema",
                schema?.schemaItem.map((s) => ({
                    ...s,
                    content: {
                        ...s.content,
                        value: fileSchema.includes(s.content.type) ? { data: "", mimetype: "", filename: "", duration: "" } : [""],
                    },
                })) || []
            );
            if (schema?.schemaItem[0]?.content?.type === "minimum-schema") {
                setMinimumSchema(true);
                setPeriodDayNoLimit(false);
            } else {
                setMinimumSchema(false);
                setPeriodDayNoLimit(true);
            }
            setDraft(schema?.draft || false);
        } else {
            setValue("articleSchema", []);
            setMinimumSchema(false);
            setPeriodDayNoLimit(true);
        }
    };

    // 請求先を配信先に合わせる
    const convertDeliveryOrganizationsToOrganizationBillingRules = () => {
        setOrganizationBillingRules(deliveries.filter(v => v.deliveryGroup.length > 0 && v.articleType === true)
            .map((v) => ({ organizationId: v.organizationId, name: v.name, billingName: v.billingName, billingRuleId: 0 , index: v.index, billingDatas: billingDatas || []})));
    };
    const onSubmitInternal = (data: any) => {
        save(data);
    };

    if (articleApprove) {
        // TODO: articleとdisabled以外は内容が被っているので整理する
        return (
            <article>
                <section>
                    <SectionTitle>基本情報</SectionTitle>
                    <div>
                        <InputBasic container spacing={3}>
                            <Grid item xs={3}>
                                <label htmlFor="id">コンテンツID</label>
                                <input type="text" id="id" value={article?.id} disabled />
                            </Grid>
                            <Grid item xs={3}>
                                <label htmlFor="user">コンテンツ所有者</label>
                                <input type="text" id="user" value={article?.createdBy.name} disabled />
                            </Grid>
                            <Grid item xs={3}>
                                <label htmlFor="status">コンテンツ状態</label>
                                <input type="text" id="status" value={article?.status} disabled />
                            </Grid>

                            <Grid item xs={6}>
                                <label htmlFor="title">タイトル</label>
                                <input
                                    type="text"
                                    id="title"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                {" "}
                            </Grid>

                            <Grid item xs={6}>
                                <label htmlFor="articleType">コンテンツタイプ</label>
                                <SelectArticleType
                                    value={articleType}
                                    defaultOption={<option value="">未選択</option>}
                                    onChange={handleChangeArticleType}
                                    isEdit={isEdit}
                                />
                            </Grid>
                        </InputBasic>

                        {articleType && (
                            <ArticleSchemaForm
                                control={control}
                                errors={errors.articleSchema}
                                register={register}
                                setValue={setValue}
                            />
                        )}
                    </div>
                </section>

                <section>
                    <SectionTitle>スケジュール</SectionTitle>
                    <div>
                        <InputSchedule container spacing={3}>
                            <Grid item xs={6}>
                                <label htmlFor="schedule">掲載期間</label>
                                <LabelRadio>
                                    <Stack spacing={1} direction="row">
                                        <input
                                            type="radio"
                                            checked={!periodDayNoLimit || checkMinimumSchema}
                                            onChange={(e) => setPeriodDayNoLimit(!e.target.checked)}
                                        /><span></span>
                                        <InputDate
                                            type="date"
                                            value={periodDayStartDate}
                                            onChange={(e) => setPeriodDayStartDate(e.target.value)}
                                        />
                                        <InputTime
                                            type="time"
                                            value={periodDayStartTime}
                                            onChange={(e) => setPeriodDayStartTime(e.target.value)}
                                        />
                                        <span>〜</span>
                                        <InputDate
                                            type="date"
                                            value={periodDayEndDate}
                                            onChange={(e) => setPeriodDayEndDate(e.target.value)}
                                        />
                                        <InputTime
                                            type="time"
                                            value={periodDayEndTime}
                                            onChange={(e) => setPeriodDayEndTime(e.target.value)}
                                        />
                                    </Stack>
                                </LabelRadio>
                                <LabelRadio>
                                    <input
                                        type="radio"
                                        checked={periodDayNoLimit && !checkMinimumSchema}
                                        onChange={(e) => setPeriodDayNoLimit(e.target.checked)}
                                        disabled={checkMinimumSchema}
                                    /><span></span>
                                    <b>無期限で掲載する</b>
                                </LabelRadio>
                            </Grid>

                            <Grid item xs={4}>
                                <label>繰返し（曜日指定）</label>
                                <InputWeekly spacing={1} direction="row">
                                    <SelectDay value={periodWeek} onChange={setPeriodWeek} />
                                </InputWeekly>

                                <label>繰返し（日付指定）</label>
                                <InputCalender>
                                    <SelectDate value={periodDay} onChange={setPeriodDay} />
                                </InputCalender>
                            </Grid>

                            <Grid item xs={2}>
                                <label htmlFor="periodTimeStart">掲載時間</label>
                                <label>
                                    <Stack spacing={1} direction="row">
                                        <InputTime
                                            type="time"
                                            id="periodTimeStart"
                                            value={periodTimeStart}
                                            onChange={(e) => setPeriodTimeStart(e.target.value)}
                                        />
                                        <span>〜</span>
                                        <InputTime
                                            type="time"
                                            id="periodTimeEnd"
                                            value={periodTimeEnd}
                                            onChange={(e) => setPeriodTimeEnd(e.target.value)}
                                        />
                                    </Stack>
                                </label>
                            </Grid>
                        </InputSchedule>
                    </div>
                </section>

                <section>
                    <SectionTitle>配信先</SectionTitle>
                    <div>
                        <DeliveryOrganizationsFormGroup
                            value={deliveries}
                            onChange={setDeliveries}
                        />
                    </div>
                </section>
                {user.data?.checkAdmin ? (
                    <section>
                        <SectionTitle>請求書</SectionTitle>
                        <div>
                            <ButtonSetBilling
                                variant="outlined"
                                type="button"
                                onClick={convertDeliveryOrganizationsToOrganizationBillingRules}
                            >
                                配信先に合わせる
                            </ButtonSetBilling>

                            <OrganizationBillingDatasInput
                                value={organizationBillingRules}
                                onChange={setOrganizationBillingRules}
                            />
                        </div>
                    </section>
                ):(<section></section>)}

                {(!article || (article && checkCreatedBy(article))) ? (
                    <BarFooter>
                        <Stack spacing={2} direction="row">
                            <ButtonWhite variant="outlined" disabled={checkSaveAs} type="button" onClick={() => navigate(-1)}>
                                キャンセル
                            </ButtonWhite>
                            <ButtonWhite variant="outlined" disabled={checkSaveAs} type="button" onClick={() => setSaveAction(SaveAction.Draft)}>
                                下書き保存
                            </ButtonWhite>
                            {/* 承認依頼・通知を無効にするためコメントアウト
                            <div>
                                
                                <LabelRadio>
                                    <input
                                        type="radio"
                                        checked={saveAction === SaveAction.Publish}
                                        disabled={checkApprove(article) === SaveAction.Request}
                                        onChange={() => setSaveAction(SaveAction.Publish)}
                                    /><span></span>
                                    そのまま掲載
                                </LabelRadio>

                                <LabelRadio>
                                    <input
                                        type="radio"
                                        checked={saveAction === SaveAction.PublishAndNotify}
                                        disabled={checkApprove(article) === SaveAction.Request}
                                        onChange={() => setSaveAction(SaveAction.PublishAndNotify)}
                                    /><span></span>
                                    通知して掲載
                                </LabelRadio>

                                <LabelRadio>
                                    <input
                                        type="radio"
                                        checked={saveAction === SaveAction.Request}
                                        onChange={() => setSaveAction(SaveAction.Request)}
                                    /><span></span>
                                    承認依頼
                                </LabelRadio>
                                <input
                                    type="text"
                                    value={saveComment}
                                    placeholder="確認コメントを入力してください"
                                    onChange={(e) => setSaveComment(e.target.value)}
                                />
                            </div>
                            コメントアウトここまで */}
                            <Button variant="contained" disabled={checkSaveAs} type="submit">
                                掲載
                            </Button>
                        </Stack>
                        {deleteButton}
                    </BarFooter>
                ):( <BarFooter>
                        <Button variant="contained" disabled={checkSaveAs} type="button" onClick={() => navigate(-1)}>
                                戻る
                        </Button>
                    </BarFooter>
                )}
            </article>
        );
    }

    else {
        return (
            <form onSubmit={handleSubmit(onSubmitInternal)}>
                <section>
                    <SectionTitle>基本情報</SectionTitle>
                    <div>
                        <InputBasic container spacing={3}>
                            <Grid item xs={3}>
                                <label htmlFor="id">コンテンツID</label>
                                <input type="text" id="id" value={article?.id} disabled />
                            </Grid>
                            <Grid item xs={3}>
                                <label htmlFor="user">コンテンツ所有者</label>
                                <input type="text" id="user" value={article?.createdBy.name} disabled />
                            </Grid>
                            <Grid item xs={3}>
                                <label htmlFor="status">コンテンツ状態</label>
                                <input type="text" id="status" value={article?.status} disabled />
                            </Grid>

                            <Grid item xs={6}>
                                <label htmlFor="title">タイトル</label>
                                <input type="text" id="title" value={title} required onChange={(e) => setTitle(e.target.value)} />
                            </Grid>
                            <Grid item xs={6}>
                                {" "}
                            </Grid>

                            <Grid item xs={6}>
                                <label htmlFor="articleType">コンテンツタイプ</label>
                                <SelectArticleType
                                    value={articleType}
                                    defaultOption={<option value="">未選択</option>}
                                    onChange={handleChangeArticleType}
                                    isEdit={isEdit}
                                />
                            </Grid>
                        </InputBasic>

                        {articleType && (
                            <ArticleSchemaForm
                                control={control}
                                errors={errors.articleSchema}
                                register={register}
                                setValue={setValue}
                            />
                        )}
                    </div>
                </section>

                <section>
                    <SectionTitle>スケジュール</SectionTitle>
                    <div>
                        <InputSchedule container spacing={3}>
                            <Grid item xs={6}>
                                <label htmlFor="schedule">掲載期間</label>
                                <LabelRadio>
                                    <Stack spacing={1} direction="row">
                                        <input
                                            type="radio"
                                            checked={!periodDayNoLimit || checkMinimumSchema}
                                            onChange={(e) => setPeriodDayNoLimit(!e.target.checked)}
                                        /><span></span>

                                        <InputDate
                                            type="date"
                                            value={periodDayStartDate}
                                            onChange={(e) => setPeriodDayStartDate(e.target.value)}
                                        />
                                        <InputTime
                                            type="time"
                                            value={periodDayStartTime}
                                            onChange={(e) => setPeriodDayStartTime(e.target.value)}
                                        />
                                        <span>〜</span>
                                        <InputDate
                                            type="date"
                                            value={periodDayEndDate}
                                            onChange={(e) => setPeriodDayEndDate(e.target.value)}
                                        />
                                        <InputTime
                                            type="time"
                                            value={periodDayEndTime}
                                            onChange={(e) => setPeriodDayEndTime(e.target.value)}
                                        />
                                    </Stack>
                                </LabelRadio>
                                <LabelRadio>
                                    <input
                                        type="radio"
                                        checked={periodDayNoLimit && !checkMinimumSchema}
                                        onChange={(e) => setPeriodDayNoLimit(e.target.checked)}
                                        disabled={checkMinimumSchema}
                                    /><span></span>
                                    <b>無期限で掲載する</b>
                                </LabelRadio>
                            </Grid>

                            <Grid item xs={4}>
                                <label>繰返し（曜日指定）</label>
                                <InputWeekly spacing={1} direction="row">
                                    <SelectDay value={periodWeek} onChange={setPeriodWeek} />
                                </InputWeekly>

                                <label>繰返し（日付指定）</label>
                                <InputCalender>
                                    <SelectDate value={periodDay} onChange={setPeriodDay} />
                                </InputCalender>
                            </Grid>

                            <Grid item xs={2}>
                                <label htmlFor="periodTimeStart">掲載時間</label>
                                <label>
                                    <Stack spacing={1} direction="row">
                                        <InputTime
                                            type="time"
                                            id="periodTimeStart"
                                            value={periodTimeStart}
                                            onChange={(e) => setPeriodTimeStart(e.target.value)}
                                        />
                                        <span>〜</span>
                                        <InputTime
                                            type="time"
                                            id="periodTimeEnd"
                                            value={periodTimeEnd}
                                            onChange={(e) => setPeriodTimeEnd(e.target.value)}
                                        />
                                    </Stack>
                                </label>
                            </Grid>
                        </InputSchedule>
                    </div>
                </section>

                <section>
                    <SectionTitle>配信先</SectionTitle>
                    <div>
                        <DeliveryOrganizationsFormGroup value={deliveries} onChange={setDeliveries} />
                    </div>
                </section>
                {user.data?.checkAdmin ? (
                    <section>
                        <SectionTitle>請求書</SectionTitle>
                        <div>
                            <ButtonSetBilling
                                variant="outlined"
                                type="button"
                                onClick={convertDeliveryOrganizationsToOrganizationBillingRules}
                            >
                                配信先に合わせる
                            </ButtonSetBilling>

                            <OrganizationBillingDatasInput
                                value={organizationBillingRules}
                                onChange={setOrganizationBillingRules}
                            />
                        </div>
                    </section>
                ):(<section></section>)}

                {(!article || (article && checkCreatedBy(article))) ? (
                    <BarFooter>
                        <Stack spacing={2} direction="row">
                            <ButtonWhite variant="outlined" disabled={checkSaveAs} type="button" onClick={() => navigate(-1)}>
                                キャンセル
                            </ButtonWhite>
                            <ButtonWhite variant="outlined" disabled={checkSaveAs} type="submit" onClick={() => setSaveAction(SaveAction.Draft)}>
                                下書き保存
                            </ButtonWhite>
                            {/* 承認依頼・通知を無効にするためコメントアウト
                            <div>
                                <LabelRadio>
                                    <input
                                        type="radio"
                                        checked={saveAction === SaveAction.Publish}
                                        disabled={checkApprove(article) === SaveAction.Request}
                                        onChange={() => setSaveAction(SaveAction.Publish)}
                                    /><span></span>
                                    そのまま掲載
                                </LabelRadio>

                                <LabelRadio>
                                    <input
                                        type="radio"
                                        checked={saveAction === SaveAction.PublishAndNotify}
                                        disabled={checkApprove(article) === SaveAction.Request}
                                        onChange={() => setSaveAction(SaveAction.PublishAndNotify)}
                                    /><span></span>
                                    通知して掲載
                                </LabelRadio>

                                <LabelRadio>
                                    <input
                                        type="radio"
                                        checked={saveAction === SaveAction.Request}
                                        onChange={() => setSaveAction(SaveAction.Request)}
                                    /><span></span>
                                    承認依頼
                                </LabelRadio>
                                <input
                                    type="text"
                                    value={saveComment}
                                    placeholder="確認コメントを入力してください"
                                    onChange={(e) => setSaveComment(e.target.value)}
                                />
                            </div>
                            コメントアウトここまで */}
                            <Button variant="contained"  disabled={checkSaveAs} type="submit">
                                掲載
                            </Button>
                        </Stack>
                        {deleteButton}
                    </BarFooter>
                ):( <BarFooter>
                        <Button variant="contained" disabled={checkSaveAs} type="button" onClick={() => navigate(-1)}>
                            戻る
                        </Button>
                    </BarFooter>
                )}
            </form>
        );
    }
}

export function date(dateTime?: string): string {
    if (!dateTime) {
        return "";
    }
    return format(parse(dateTime, "yyyy-MM-dd HH:mm:ss", new Date()), "yyyy-MM-dd");
}

export function time(dateTime?: string): string {
    if (!dateTime) {
        return "";
    }
    return format(parse(dateTime, "yyyy-MM-dd HH:mm:ss", new Date()), "HH:mm:ss");
}

export function periodDayString(date?: string, time?: string, noLimit?: boolean): string | null | undefined {
    if (noLimit) {
        return null;
    }
    if (!date || !time) {
        return undefined;
    }
    if(time.length < 6){
        time += ":00";
    }
    return date + " " + time;
}
