import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";
import { Id } from "./id";
import { Page } from "./page";
import { Article, ArticleForm, ArticleQuery, ArticleUpdateStatusForm } from "./article";
import { ScheduleQuery, ScheduleForm } from "./schedule";

export const articleApi = createApi({
    reducerPath: "articleApi",
    baseQuery,
    tagTypes: ['Article'],
    endpoints: (builder) => ({
        getArticles: builder.query<Page<Article>, ArticleQuery>({
            query: (params) => ({
                url: "articles/manage/list",
                params,
            }),
            providesTags: ['Article'],
        }),
        getArticle: builder.query<Article, Id>({
            query: (id) => `articles/manage/${id}`,
            providesTags: ['Article'],
        }),
        getDailySchedule: builder.query<Article[], ScheduleQuery>({
            query: (params) => ({
                url: `schedule/daily`,
                params,
            }),
            providesTags: ['Article'],
        }),
        getMonthlySchedule: builder.query<Article[], ScheduleQuery>({
            query: (params) => ({
                url: `schedule/monthly`,
                params,
            }),
            providesTags: ['Article'],
        }),
        createArticle: builder.mutation<void, ArticleForm>({
            query: (body) => {
                return {
                    url: `articles/manage`,
                    method: "post",
                    body,
                };
            },
            invalidatesTags: ['Article'],
        }),
        updateArticle: builder.mutation<void, ArticleForm>({
            query: ({ id, ...body }) => ({
                url: `articles/manage/${id}`,
                method: "post",
                body,
            }),
            invalidatesTags: ['Article'],
        }),
        bulkDeleteArticles: builder.mutation<void, number[]>({
            query: (articleIds) => ({
                url: `articles/manage`,
                method: "delete",
                body: {
                    articleIds,
                },
            }),
            invalidatesTags: ['Article'],
        }),
        bulkUpdateArticleStatus: builder.mutation<void, ArticleUpdateStatusForm>({
            query: (body) => ({
                url: "articles/manage/status",
                method: "post",
                body,
            }),
            invalidatesTags: ['Article'],
        }),
        updateDailySchedule: builder.mutation<void, ScheduleForm>({
            query: ({ deliveryGroupId, body }) => ({
                url: `schedule/daily/${deliveryGroupId}`,
                method: "post",
                body,
            }),
            invalidatesTags: ['Article'],
        }),
        updateMonthlySchedule: builder.mutation<void, ScheduleForm>({
            query: ({ deliveryGroupId, body }) => ({
                url: `schedule/monthly/${deliveryGroupId}`,
                method: "post",
                body,
            }),
            invalidatesTags: ['Article'],
        }),
    }),
});

export const {
    useGetArticlesQuery,
    useGetArticleQuery,
    useGetDailyScheduleQuery,
    useGetMonthlyScheduleQuery,
    useCreateArticleMutation,
    useUpdateArticleMutation,
    useBulkDeleteArticlesMutation,
    useBulkUpdateArticleStatusMutation,
    useUpdateDailyScheduleMutation,
    useUpdateMonthlyScheduleMutation,
} = articleApi;
