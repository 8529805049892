import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Organization } from "../gateways/cms/organization";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";

export interface Props {
    organization: Organization;
    onOrganizationDelte: (id: number) => void;
}

const MinimumMenu = styled((props: MenuProps) => (
    <Menu 
        {...props} 
    />
    ))(({ theme }) => ({
    "#schedul-article-menu-button": {
        minWidth: 24,
    },
    "& .MuiPaper-root": {
        borderRadius: 2,
        width: 200,
        fontSize: 14,

        "& .MuiList-root": {
            padding: 0,
        },
        "& .MuiDivider-root": {
            marginTop: 0,
            marginBottom: 0,
        },
        "& .MuiList-root .delete": {
            color: "#FC6F6C",
        },
    },
}));


export const OrganizationEditMenu = ({
    organization,
    onOrganizationDelte,
}: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const close = () => {
        setAnchorEl(null);
    };
    // 各ボタンの処理
    const handleDetail = () => {
        navigate("/organizations/" + organization.id + "/edit");
        close();
    };
    const handleDelte = () => {
        if(organization.childOrganization){
            alert(organization.name + "には所属テナントが存在するため削除できません");
            close();
        }
        else{
            onOrganizationDelte(organization.id);
            close();
        }
    };

    return (
        <div>
            <Button
                id="schedul-article-menu-button"
                aria-controls={open ? "schedul-article--menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            ></Button>
            <MinimumMenu
                id="schedul-article--menu"
                MenuListProps={{
                    "aria-labelledby": "schedul-article-menu-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={close}
            >
                <MenuItem onClick={handleDetail} href={`/organization/${organization.id}/edit`}>詳細情報</MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={handleDelte} className="delete">削除</MenuItem>
            </MinimumMenu>
        </div>
    );
};
